import React from 'react';
import css from './WeekdayPeriodSelector.module.css';
import { FieldCheckbox } from '../../../components';
import classNames from 'classnames';

const WeekdayPeriodSelector = ({ id, className, name, options }) => {
  const structuredOptions = options.reduce((acc, option) => {
    const [day, period] = option.option.split('_');
    if (!acc[day]) {
      acc[day] = {};
    }
    acc[day][period] = option;
    return acc;
  }, {});

  // Extract unique periods from all days to ensure complete coverage across the table
  const periodsOfDay = new Set();
  options.forEach(option => {
    const [, period] = option.option.split('_');
    periodsOfDay.add(period);
  });

  const daysOfWeek = Object.keys(structuredOptions).sort(
    (a, b) => new Date(`next ${a}`).getDay() - new Date(`next ${b}`).getDay()
  );

  return (
    <fieldset className={classNames(className, css.root)} style={{ border: 'none' }}>
      <table className={css.table}>
        <thead>
          <tr>
            <th className={classNames(css.firstColumn, css.topLeftCorner)}>
              <div className={css.whiteCell}></div>
            </th>
            {daysOfWeek.map((day, index) => (
              <th
                key={day}
                className={classNames(css.firstRow, css.otherColumns, {
                  [css.topRightCorner]: index === daysOfWeek.length - 1,
                })}
              >
                {day.charAt(0).toUpperCase() + day.slice(1, 3)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {[...periodsOfDay].map((period, rowIndex) => (
            <tr key={period}>
              <td
                className={classNames(css.firstColumn, {
                  [css.bottomLeftCorner]: rowIndex === periodsOfDay.size - 1,
                })}
              >
                {period.charAt(0).toUpperCase() + period.slice(1)}
              </td>
              {daysOfWeek.map((day, colIndex) => (
                <td
                  key={`${day}_${period}`}
                  className={classNames(css.cell, {
                    [css.bottomRightCorner]:
                      rowIndex === periodsOfDay.size - 1 && colIndex === daysOfWeek.length - 1,
                  })}
                >
                  {structuredOptions[day] && structuredOptions[day][period] ? (
                    <div className={css.innerCell}>
                      <FieldCheckbox
                        id={`${id}.${structuredOptions[day][period].option}`}
                        name={name}
                        label={''}
                        value={structuredOptions[day][period].option}
                      />
                    </div>
                  ) : (
                    <span>-</span>
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </fieldset>
  );
};

export default WeekdayPeriodSelector;
