import React from 'react';
import PropTypes from 'prop-types';
import { getTimeZoneNames } from '../../../util/dates';

const FieldTimeZoneSelect = ({ id, name, value, onChange, className }) => {
  // Define a pattern to filter relevant time zones only.
  const relevantZonesPattern = new RegExp(
    '^(Africa|America(?!/(Argentina/ComodRivadavia|Knox_IN|Nuuk))|Antarctica(?!/(DumontDUrville|McMurdo))|Asia(?!/Qostanay)|Atlantic|Australia(?!/(ACT|LHI|NSW))|Europe|Indian|Pacific)'
  );

  // Function to render options.
  const renderOptions = () => {
    return getTimeZoneNames(relevantZonesPattern).map(tz => (
      <option key={tz} value={tz}>
        {tz}
      </option>
    ));
  };

  return (
    <select id={id} name={name} className={className} value={value} onChange={onChange}>
      <option disabled value="">
        Choose a timezone...
      </option>
      {renderOptions()}
    </select>
  );
};

FieldTimeZoneSelect.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default FieldTimeZoneSelect;
